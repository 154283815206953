@import 'colors';

html,
body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body {
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 320px;
  line-height: 1;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;

  &-thumb {
    border: 2px solid transparent;
    background: var(--color-white);
    background-clip: content-box;
    transition: 0.2s;
    border-radius: 10px;

    &:hover,
    &:active {
      background: var(--color-white);
      border-width: 1px;
    }
  }

  &-track {
    border: 1px solid transparent;
    background-clip: content-box;
    background: var(--color-background);
    border-radius: 10px;
  }
}

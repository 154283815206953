.deckName {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin: 5px 0 0;
  text-align: center;
  color: var(--color-black);
  white-space: nowrap;
}

.cardNumber {
  position: absolute;
  top: -5px;
  left: 10px;
  font-size: 16px;
}

.cardBackgroundWrapper {
  position: relative;
  background: var(--color-white);
  border-radius: 10px;
}

.cardBackground {
  background: var(--color-primary);
  width: 120px;
  height: 160px;
  border-radius: 4px;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 80px;
  width: 100%;
}

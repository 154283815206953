.deck {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
}

.deckWrapper {
  width: fit-content;
  margin: 0 auto 15px;
  padding: 7px;
  background: var(--color-white);
  border-radius: 10px;
  cursor: pointer;

  &.picked {
    background: var(--color-button);
  }
}

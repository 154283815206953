.header {
  display: flex;
  align-items: center;
  padding-inline: 10px !important;
  background: var(--color-primary) !important;
  border-bottom: 1px solid var(--color-white);
  position: relative;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  margin-left: 20px !important;
}
.sider {
  background: var(--color-background) !important;
  border-right: 1px solid white;
  height: 100vh;
  direction: rtl;
  overflow: auto;

  &.ant-layout-sider-collapsed {
    width: 0 !important;
  }

  & > div {
    direction: ltr;
  }
}
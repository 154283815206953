.cardContainer {
  display: flex;
  cursor: grab;
  border-radius: 10px;
  position: relative;

  & img {
    border-radius: 4px;
    object-fit: cover;
  }
}

.cardImage {
  width: 134px;
  height: 174px;
  transform-origin: left top;
  transition: transform 0.1s ease;
}

.cardImageSkeleton {
  width: 134px !important;
  height: 174px !important;
}

.buttonWrapper {
  position: absolute;
  top: 0;
  left: -30px;
  display: flex;
  flex-direction: column;
}

.button {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: var(--color-button);
  color: var(--color-white);
  border: none;
  margin: 0 0 3px;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }
}

.cardBackgroundWrapper {
  padding: 7px;
  background: var(--color-white);
  border-radius: 10px;
}

.footer {
  position: relative;
  background: var(--color-primary);
  padding: 15px;
  border-top: 1px solid white;

  &.collapsed {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;

    & .footerButtons {
      display: none;
    }

    & .cardsWrapper {
      display: none;
    }
  }
}

.footerButton {
  background: var(--color-button);
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: var(--color-white);
  cursor: pointer;
  font-size: 14px;
  outline: none;
}

.footerButtons {
  display: flex;
  margin: 0 0 10px;

  & > .footerButton {
    margin: 0 5px 0 0;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;

    & > .footerButton {
      margin: 0 0 5px;
    }
  }
}

.cardsWrapper {
  display: flex;
  overflow-x: scroll;
  min-height: 160px;
  padding: 0 0 10px;

  & > div {
    margin: 0 10px 0 0;
    cursor: grab;

    &:last-child {
      margin: 0;
    }
  }
}

.triggerIcon {
  position: absolute;
  top: -25px;
  left: 10px;
  transform: rotate(-90deg);
  padding: 5px;
  background: var(--color-primary);
  cursor: pointer;
  border: 1px solid var(--color-white);
  border-left: none;

  &:hover {
    background: var(--color-primary-hover);
  }
}

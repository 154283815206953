.content {
  position: relative;
  padding: 15px 45px;
  height: calc(100vh - 330px);
  background: var(--color-background);
  display: grid;
  grid-template-rows: repeat(auto-fit, 174px);
  grid-template-columns: repeat(auto-fit, 134px);
  overflow-y: auto;
  grid-gap: 10px;
}

.imageBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  max-width: 150px;
  width: 100%;
}

.cardWrapper {
  position: absolute;
}
